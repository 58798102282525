import Link from 'next/link';
import { ArrowRight } from '@phosphor-icons/react/dist/ssr';
import { type FC, memo } from 'react';
import { GhostButton } from '../../button/ghost-button';
import { FilledButton } from '../../button/filled-button';
import { type LinkMenuItemData } from '../types';

type Props = {
  item: LinkMenuItemData;
  onClick: () => void;
};

const HamburgerLinkMenuItem: FC<Props> = memo(function ({ item, onClick }) {
  return (
    <Link href={item.url} className="block">
      {item.highlight === true ? (
        <FilledButton onClick={onClick} fullWidth className="flex flex-row items-center justify-between text-left">
          {item.label} <ArrowRight weight='bold' size={18} />
        </FilledButton>
      ) : (
        <GhostButton onClick={onClick} fullWidth className="text-left">
          {item.label}
        </GhostButton>
      )}
    </Link>
  );
});

HamburgerLinkMenuItem.displayName = 'HamburgerLinkMenuItem';
export { HamburgerLinkMenuItem };
