import { Transition, Dialog } from '@headlessui/react';
import { List, X } from '@phosphor-icons/react/dist/ssr';
import { type FC, Fragment, memo, type ReactNode, useCallback, useState } from 'react';
import { GhostButton } from '../../button/ghost-button';

type Props = {
  children: (params: {close: () => void, open: () => void}) => (ReactNode | ReactNode[]);
};

const HamburgerModal: FC<Props> = memo(function ({children}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  return (
    <>
      {/* Trigger */}
      <GhostButton
        className={'flex flex-row items-center space-x-2'}
        onClick={toggle}>
        {!isOpen ? <>
            <List size={18} weight={'bold'} />
            <span className="hidden sm:inline">Menu</span>
          </> : <>
            <X size={18} weight={'bold'} />
            <span className="hidden sm:inline">Fermer</span>
          </>}
      </GhostButton>

      {/* Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <div className="fixed inset-x-0 bottom-0 top-[72px] w-full bg-white">
            <div className="flex h-full items-start justify-start overflow-y-auto !text-left">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="container relative mx-auto border-t border-t-gray-100 p-4 transition-all">
                  {children({close, open})}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* /Modal */}
    </>
  );
});

HamburgerModal.displayName = 'HamburgerModal';
export { HamburgerModal };
