import { StoreLocatorUtils } from "@/utils/store-locator-utils";
import { type MenuItemData } from "../types";

export const mainMenuItems: MenuItemData[] = [
  {
    label: "Réservez votre box",
    url: StoreLocatorUtils.buildUrl(),
    showDesktop: false,
    highlight: true,
  },
  {
    label: "Services",
    children: [
      {
        label: "Garde-meuble libre service",
        url: "/articles/garde-meuble/",
      },
      {
        label: "Box à louer près de chez vous",
        url: "/articles/boxes-de-stockage-a-louer/",
      },
      {
        label: "Stockage / Selfstockage",
        url: "/",
      },
      {
        label: "Archivage physique",
        url: "/articles/archivage-physique-documents/",
      },
      {
        label: "Boxes à louer pour professionnels",
        url: "/articles/professionnels/",
      }
    ]
  },
  {
    label: "Les + selfstock.com",
    children: [
      {
        label: "Tarifs attractifs et sans surprise",
        url: "/centres-de-selfstockage/france/",
      },
      {
        label: "Différents boxes pour tout stocker",
        url: "/articles/boxes-pour-tout-stocker/",
      },
      {
        label: "Sécurité sans faille",
        url: "/articles/securite-sans-faille/",
      },
      {
        label: "Contenu préservé",
        url: "/articles/garde-meuble-box-etanche/",
      },
      {
        label: "Accessibilité optimale",
        url: "/articles/accessibilite-optimale/",
      },
      {
        label: "Réservation de box en ligne",
        url: "/articles/reservation-box-en-ligne/",
      },
      {
        label: "Comparatifs de solutions de stockage",
        url: "/articles/comparatif-solutions-stockage/",
      },
      {
        label: "FAQs de l'offre selfstock.com",
        url: "/articles/faqs-de-loffre-selfstock-com/ ",
      }
    ]
  },
  {
    label: "À propos",
    children: [
      {
        label: "Qui sommes-nous",
        url: "/articles/qui-sommes-nous/",
      },
      {
        label: "selfstock.com : nos centres de stockage en France",
        url: "/articles/centres-stockage-selfstock/"
      },
      {
        label: "Devenez franchisé",
        url: "https://franchise.selfstock.com/",
      },
      {
        label: "selfstock.com dans les médias",
        url: "/articles/selfstock-com-dans-les-medias/",
      },
      {
        label: "Ils nous font confiance",
        url: "/articles/ils-nous-font-confiance/",
      }
    ]
  }, 
  {
    label: "Mon compte",
    url: "https://moncompte.selfstock.com",
    showDesktop: false,
  }
];
