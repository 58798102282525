import Link from "next/link";
import { type FC, memo } from "react";
import { GhostButton } from "../button/ghost-button";
import { type LinkMenuItemData } from "./types";

type Props = {
  item: LinkMenuItemData,
  className?: string,
}

const DesktopLinkMenuItem: FC<Props> = memo(function({item, className = ''}) {
  return <Link href={item.url} className="block">
    <GhostButton fullWidth className={`text-left ${className}`}>
      {item.label}
    </GhostButton>
  </Link>;
});

DesktopLinkMenuItem.displayName = "DesktopLinkMenuItem";
export {DesktopLinkMenuItem};