import { type FC, memo } from "react";
import { DesktopChildParentMenuItem } from "./desktop-child-parent-menu-item";
import { DesktopMenuItem } from "./desktop-menu-item";
import { type MenuItemData } from "./types";

type Props = {
  item: MenuItemData
}

const DesktopChildMenuItem: FC<Props> = memo(function({item}) {
  if ("children" in item)
    return <DesktopChildParentMenuItem item={item} className={"rounded-none"} />;

  return <DesktopMenuItem item={item} className={"rounded-none"} />
});

DesktopChildMenuItem.displayName = "DesktopChildMenuItem";
export {DesktopChildMenuItem};