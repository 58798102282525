import { type FC, useContext, useEffect, useState } from 'react';
import { CartContext } from './cart-controller.context';

type Props = {
  className?: string;
};

export const CartProductCount: FC<Props> = function ({ className }) {
  const cartController = useContext(CartContext);
  const [productCount, setProductCount] = useState<number | undefined>(
    undefined
  );

  function onCartUpdated() {
    cartController!.getProductCount().then((count) => setProductCount(count));
  }

  useEffect(() => {
    cartController!.emitter.on('cartUpdated', onCartUpdated);
    cartController!.getProductCount().then((count) => setProductCount(count));

    return () => {
      cartController!.emitter.off('cartUpdated', onCartUpdated);
    }
  });

  if (!productCount) return null;

  return <div className={className}>{productCount}</div>;
};
