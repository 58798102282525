import { type FC, memo } from "react";
import { type MenuItemData } from "../types";
import { HamburgerLinkMenuItem } from "./hamburger-link-menu-item";
import { HamburgerParentMenuItem } from "./hamburger-parent-menu-item";

type Props = {
  item: MenuItemData,
  onClick: () => void,
}

const HamburgerMenuItem: FC<Props> = memo(function({item, onClick}) {
  if ("children" in item) 
    return <HamburgerParentMenuItem item={item} onChildrenClick={onClick} />

  return <HamburgerLinkMenuItem item={item} onClick={onClick} />;
});

HamburgerMenuItem.displayName = "HamburgerMenuItem";
export {HamburgerMenuItem};