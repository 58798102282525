import { useState, useEffect, useCallback } from 'react';

export function useIsFixed() {
  const [isFixed, setFixed] = useState<boolean>(false);

  const handleWindowScroll = useCallback(() => {
    if (typeof window === 'undefined') return;

    if (window.scrollY < 20) {
      setFixed(false);
      return;
    }

    setFixed(true);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('scroll', handleWindowScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  });

  return isFixed;
}
