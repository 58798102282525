"use client";

import { CartContextProvider } from "@/components/cart/cart-controller.context";
import { CartController, getHttpClient } from "@/utils";
import { type PropsWithChildren, useRef, type FC } from "react";

export const CartProvider: FC<PropsWithChildren> = ({children}) => {
  const cartController = useRef(new CartController({
    axios: getHttpClient()
  }));

  return <CartContextProvider value={cartController.current}>
    {children}
  </CartContextProvider>
}