import { Disclosure, Transition } from '@headlessui/react';
import { CaretDown } from '@phosphor-icons/react/dist/ssr';
import { type FC, memo } from 'react';
import { GhostButton } from '../../button/ghost-button';
import { type ParentMenuItemData } from '../types';
import { HamburgerMenuItem } from './hamburger-menu-item';

type Props = {
  item: ParentMenuItemData;
  onChildrenClick: () => void,
};

const HamburgerParentMenuItem: FC<Props> = memo(function ({ item, onChildrenClick}) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button as={GhostButton} fullWidth className="flex items-center justify-between text-left">
            {item.label}

            <CaretDown
              size={18}
              className={`${
                open ? 'rotate-180' : ''
              } transition-transform`}
              weight="bold"
            />
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Disclosure.Panel className="py-4 pl-4">
              <ul className="space-y-4">
                {item.children.map((item, i) => (
                  <li key={i}>
                    <HamburgerMenuItem item={item} onClick={onChildrenClick} />
                  </li>
                ))}
              </ul>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
});

HamburgerParentMenuItem.displayName = 'HamburgerParentMenuItem';
export { HamburgerParentMenuItem };
