"use client";

import { Transition, Dialog } from "@headlessui/react";
import { debounce } from "lodash";
import { type ReactNode, type FC, useState, Fragment } from "react";
import { Heading } from "../heading";
import { Cart } from "./cart";
import { reportViewCart } from "@/lib/analytics";

type TriggerRenderCallbackParams = {
  openModal: () => void,
  closeModal: () => void,
  isOpen: boolean,
  productCount?: number,
}

type Props = {
  trigger: (params: TriggerRenderCallbackParams) => ReactNode;
};

export const CartModal: FC<Props> = ({ trigger }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = debounce(() => {
    setIsOpen(false);
  }, 100);

  const openModal = debounce(() => {
    setIsOpen(true);
    reportViewCart();
  }, 100);

  return (
    <>
      {trigger({isOpen, openModal, closeModal, productCount: 20})}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal} unmount={false}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          {/* /Overlay */}

          <div className="fixed inset-x-0 bottom-0 top-24 w-full overflow-y-auto sm:left-auto sm:max-w-md">
            <div className="flex min-h-full items-start justify-start p-4 pb-8 text-center lg:p-8">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full overflow-x-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* Title  */}
                  <Dialog.Title as="h2">
                    <Heading as={'span'} level={3}>
                      Mon panier
                    </Heading>
                  </Dialog.Title>

                  <Cart controls={{checkoutButton: true}}/>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};