"use client";

import Link from 'next/link';
import { StoreLocatorUtils } from '@/utils/store-locator-utils';
import { ShoppingCartSimple, User, X } from '@phosphor-icons/react/dist/ssr';
import { type FC } from 'react';
import { ResponsiveLogo } from '../brand/responsive-logo';
import { GhostButton } from '../button/ghost-button';
import { FilledButton } from '../button/filled-button';
import { CartProductCount } from '../cart/cart-product-count';
import { CartModal } from '../cart/cart-modal';
import { mainMenuItems } from './data/main-menu-items';
import { DesktopMenuItem } from './desktop-menu-item';
import { HamburgerMenuItem } from './hamburger/hamburger-menu-item';
import { HamburgerModal } from './hamburger/hamburger-modal';
import { useIsFixed } from './hooks/use-is-fixed';

type Props = {
  id?: string,
};

const Navbar: FC<Props> = function (props) {
  const isFixed = useIsFixed();

  return (
    <nav id={props.id}
      className={`${
        isFixed ? 'shadow-xl' : ''
      } fixed inset-x-0 top-0 z-50 flex h-[74px] items-center bg-white transition-shadow lg:h-[88px]`}>
      <div className="container mx-auto grid grid-cols-3 p-4 lg:flex lg:flex-row lg:items-center lg:justify-between">
        {/* Desktop menu */}
        <div className="hidden flex-row items-center gap-2 lg:flex">
          <Link
            href={'/'}
            title={"Retourner à l'accueil"}
            className={'xl:mr-4'}>
            <ResponsiveLogo />
          </Link>

          {...mainMenuItems.filter(item => item.showDesktop ?? true).map((item, i) => (
            <DesktopMenuItem key={i} item={item} />
          ))}
        </div>
        {/* /Desktop menu */}

        {/* Mobile Hamburger menu button */}
        <div className={`mobile-menu-button col-span-1 lg:hidden`}>
          <HamburgerModal>
            {({ close }) => (
              <ul className="space-y-4">
                {mainMenuItems.filter(item => item.showMobile ?? true).map((item, i) => (
                  <li key={i}>
                    <HamburgerMenuItem item={item} onClick={close} />
                  </li>
                ))}
              </ul>
            )}
          </HamburgerModal>
        </div>
        {/* /Mobile Hamburger menu button */}

        {/* Mobile Logo */}
        <div className="mobile-menu-logo col-span-1 flex items-center justify-center lg:hidden">
          <Link href={'/'} title={"Retourner à l'accueil"}>
            <ResponsiveLogo />
          </Link>
        </div>
        {/* /Mobile Logo */}

        {/* My Account & Cart */}
        <div className="col-span-1 flex items-center justify-end gap-2">
          <Link href={StoreLocatorUtils.buildUrl()}>
            <FilledButton className={'hidden lg:block'}>
              Réservez votre box
            </FilledButton>
          </Link>

          <Link href={'https://moncompte.selfstock.com/'} target={'_blank'}>
            <GhostButton className={'flex flex-row items-center gap-2'}>
              <User size={18} weight={'bold'} />
              <span className={'hidden lg:inline'}>Compte</span>
            </GhostButton>
          </Link>

          <CartModal
            trigger={(params) =>
              params.isOpen ? (
                <GhostButton
                  className={'flex flex-row items-center gap-2'}
                  onClick={() => params.closeModal()}>
                  <X size={18} weight={'bold'} />
                  <span className={'hidden lg:inline'}>Fermer</span>
                </GhostButton>
              ) : (
                <GhostButton
                  className={'relative flex flex-row items-center gap-2'}
                  onClick={() => params.openModal()}>
                  <ShoppingCartSimple size={18} weight={'bold'} />
                  <span className={'hidden lg:inline'}>Panier</span>
                  {/* Cart item count */}
                  <CartProductCount
                    className={
                      'absolute -right-2 -top-2 flex size-6 items-center justify-center rounded-full bg-brand text-2xs text-white'
                    }
                  />
                </GhostButton>
              )
            }
          />
        </div>
        {/* /My Account & Cart */}
      </div>
    </nav>
  );
};

Navbar.displayName = 'Navbar';
export { Navbar };
