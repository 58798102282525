import { type FC, memo } from "react";
import { DesktopLinkMenuItem } from "./desktop-link-menu-item";
import { DesktopParentMenuItem } from "./desktop-parent-menu-item";
import { type MenuItemData } from "./types";

type Props = {
  item: MenuItemData,
  className?: string,
}

const DesktopMenuItem: FC<Props> = memo(function({item, className}) {
  if ("children" in item)
    return <DesktopParentMenuItem item={item} />;

  return <DesktopLinkMenuItem item={item} className={className} />
});

DesktopMenuItem.displayName = "DesktopMenuItem";
export {DesktopMenuItem};