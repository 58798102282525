import {type FC, memo} from "react";
import Image from 'next/image';

export const logoUrl = '/images/logo-selfstock.svg';
export const logoSquareUrl = '/images/logo-selfstock-square.svg';

const ResponsiveLogo: FC = memo(function () {
  return <div className={"relative flex h-[48px] items-center justify-center"}>
    {/* Classic logo */}
    <Image 
      src={logoUrl} 
      width={222} 
      height={48} 
      alt="Logo de la marque Selfstock.com"
      className={"hidden size-full sm:block lg:hidden 2xl:block"}
    />

    {/* Square logo */}
    <Image 
      src={logoSquareUrl} 
      width={43} 
      height={43} 
      alt="Logo de la marque Selfstock.com"
      loading="eager"
      className={"block size-full sm:hidden lg:block 2xl:hidden"} />
  </div>
});

ResponsiveLogo.displayName = 'ResponsiveLogo';
export { ResponsiveLogo };